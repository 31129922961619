import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1A85FF",
      lightMain: "#006AE5",
      darkMain: "#42526E",
      background: "#F8F9FF",
      light: "#FFFFFF",
      dark: "#165BB5",
      opacityOrange: "#ffa50040",
      translucentWhite: "#FFFFFF78",
      darkColor: "#141414",
      orangeOpacity: '#e28c0e9c',
      shadowTable: '#4F3E4217',
      descHome: '#B0B0B0',
      text: "#172B4D",
      borderIcon: "#BFBFBF66",
      sky: "#CDE4FF",
      grey: "#A2A1A8",
      gray: '#a2a1a80d',
      black: "#16151C",
      borderBlack: "#00000036",
      label: "#272727",
      colorInput: "#90A3BF",
      Charcoal: "#1A202C",
      blackIcon: "#475156",
      darkGrey: "#77878F",
      blueBlack: "#64748B",
      borderDarkBlue: "#EFEFEF",
      aliceBlue: "#F0F8FF",
      lightAliceBlue: "#FAFAFA",
      lightDoveGrey: "#212121",
      lightMain2: "#5faaff",
      whiteSmoke: "#F8F8F8",
      shadowInput: "#00000012",
      doveGrey: "#121826",
      arrow: "#A4A4A4",
      red: "#FF0000",
      transparent: "#FBFBFB",
      blueProgress: "#0077FF",
      brownGrey: "#B4B4B4",
      dashedGrey: "#E3E3E3",
      bgGrey: "#F9FAFB",
      lightGreen: "#E5FFE3",
      darkGreen: "#2FB500",   
      smoke:"#DCEEFF63",
      lightBlueBlack:"#505050",
       textBlueBlack:"#33334F",
      darkBlack:"#1C1C1C",
      lightWhiteGrey:"#8B96A5",
      lightGray:"#F2F2F2",     
      lightSky:"#1985FF1A",
      darkBrown:"#3A354199",
      lightIceBlue:"#E9F3FF",
      greyLightOpacity:"#F6F6F6",
      trueBlack:"#000000",
      darkGreyBorder:"#ECEEF6",
      darkGreyText:"#C4C4C4",
      darkBlue: '#3A3A3A',
      purpleBlack:"#030229",
      boxGray:"#F7F7F8",
      borderBtnGrey:"#BFBFBF38",
      slightGray:"#E7E7E7",
      lightBlue:"#00A3FF",
      darkHeaderGrey:"#515151",
      ToggleBlack:"#465060",
      purple:"#3E6CC1",
      lightPurple:"#f5f7ff",
      lightOrange:"#b07d2d",
      orange:"#fcb040",
      black2:"#050b04",
      lightBlack:"#2a2929"
    },
    dark: {
      charcoalBlack: "#121212",
      dustyBlue: "#7A869A",
      colorCart: " #212121",
      backgroundIcons: "#00000082",
      heading:"#1A1A1A",
      black08Opacity: '#00000014',
      darkBlack:"#261E27",
      black:"#161616",
      textGay:"#656565"
    },
    gray: {
      btnPrice: "#E6E6E6",
      numPrice: "#737373",
      borderFaild: "#8080807d",
      colorfeild: "#797979",
      colorSummary: "#5B5B5B",
      borderColor: "#EBEBEB",
      grayBackground: "#FDFDFD",
      border: "#EAEAEA",
      slightGray: "#85808033",
      btnContact: "#B4B4B4",
      overlayBlack: "#00000063",
      overlayGray: '#767177',
      lightBorderGray:"#ECECEC",
      lightGray: "#C3D4E933",
    },
    secondary: {
      main: "#ccc",
      background: "#E0E0E0",
      light: "#D8D8D8",
      menuHeaderIcone: "#574146",
    },
    neutral: {
      medium: "#373737",
      background: "#F1F1F1",
      textDark: "#32435C",
      textLight: "#6A635B",
      heading: "#1D1D45",
      subText: "#828282",
      icon: "#8E8E8E",
      paleGray: "#E8E8E8",
      butnGray: "#50505033",
      borderColor: "#E4E7E9",
      blueCyan: "#E2E8F0",
      darkBlueGrey: "#5F6C72",
      lightPaleGray: "#E8E8E880",
      greyBlue: "#5A5A5A",
      metallicGray: "#C0C0C0",
      MoustacheGrey: "#7E7E7E",
      boxGray: "#F8F8F8",
      lightGreyIcon: "#707B81",
      grayBorder:"#DEE2E7",
      lightGrayBorder:"#E6E6E6",
      SlateGray:"#5B5B5B",
      rating: '#F6F6F6',
      lightGray: "#fbfbfb",
    },
    customShadows: {
      light: "#00000012",
      darkBlack: "#00000014",
      darkLight: "#00000008",
      dark: "#38383814",
      darkShadow: "#0000000F",
      lightGrey:"#E2E2E240"
    },
    status: {
      success: "#06C149",
      tax:"#00B517",
      darkGreen:"#00893A",
      lightGreen: "#e6f3eb",
      warning: "#FACC15",
      pending:"#FF7E27",
      error: "red",
      discount:"#FA3434",
      cancel:"#F52F2F",
      lightRed:"#fcc1c1",
      darkRed:"#F90C0D",
      pink:"#fee7e7",
      info: "#246BFD",
      disabledBtn: "#A3C7D9",
      background: "#E57B00",
      star: '#FABF35',
      lightPink:"#FFDBD166"
    },
    text: {
      primary: "#0D0D0D",
      border: "#0D0D0D33",
      headerFont: "Ottomanopee one, sans-serif",
      authFont: "Lexend",
    },
    action: {
      hover: "#E0E0E0",
      selected: "#F0F0F0",
    },
  },
  
  avatarGradients: [
    ['#96DEDA', '#50C9C3'],
    ['#667EFE', '#944BC0'],
    ['#FFB199', '#FF0844'],
    ['#64D2FF', '#0A3CFF'],
    ['#537895', '#09203F'],
    ['#30D2BE', '#3584A7'],
    ['#FFB3A9', '#F56790'],
  ],
  
  spacing: 8,
  direction: "rtl",
  shape: {
    borderRadius: 4,
    borderRadiusLarge: 30,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    headerFont: "Otomanopee One, sans-serif",
    matrixFont: "Dot Matrix, sans-serif",
    Columbia: 'Aptos, sans-serif',
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#F52F2F', 
          color: '#ffff', 
        },
       
      },
    },
  },
});

export default theme;
