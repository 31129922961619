import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  useTheme,
  Divider,
} from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { auth, db } from "../../../configs/firebase";
import { setUserData } from "../../../redux/slices/appStateSlice";
import SocialAuthButton from "../../../components/common/SocialAuthButton";
import loginPic from "../../../assets/Images/login_side_image.jpeg";
import logo from "../../../assets/Logo/logoBlack.png";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Loader from "../../../components/common/Loader";
const LoginForm = ({ onForgetPasswordClick }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const isAppleDevice = /iPhone|iPad|Macintosh/i.test(navigator.userAgent);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required"),
    password: Yup.string().min(1).required("Password is required"),
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const { email, password } = values;
    const authInstance = getAuth();

    try {
      // Set the appropriate persistence based on the "Remember Me" checkbox
      const persistence = rememberMe
        ? browserLocalPersistence // Store session in Local Storage if "Remember Me" is checked
        : browserSessionPersistence; // Store session in Session Storage if "Remember Me" is unchecked

      // Apply the persistence setting BEFORE logging the user in
      await setPersistence(authInstance, persistence);

      // Store session start time and persistence type in sessionStorage
      if (!rememberMe) {
        sessionStorage.setItem("sessionStartTime", Date.now());
        sessionStorage.setItem("persistenceType", "session");
      } else {
        sessionStorage.setItem("persistenceType", "local"); // Mark as local storage persistence
      }

      const userCredential = await signInWithEmailAndPassword(
        authInstance,
        email.trim(),
        password
      );

      const user = userCredential.user;

      const userDocRef = db.collection("users").doc(user?.uid);
      const userSnapshot = await userDocRef.get();

      if (userSnapshot.exists) {
        const userData = userSnapshot.data();
        // Check if the user is deleted
        if (userData?.isDeleted) {
          message.warning(
            "Your account has been deleted. You have been signed out."
          );
          auth.signOut();
          return;
        }

        await userDocRef.update({
          "lastLoginInfo.lastLogin": new Date(),
        });

        dispatch(setUserData(userData));

        message.success("Login successful.");
        const redirectPath = sessionStorage.getItem("redirectAfterLogin");
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate("/home");
        }
      } else {
        message.error("User data not found.");
      }
    } catch (error) {
      if (error.code === "auth/invalid-login-credentials") {
        message.error("Incorrect password or email");
      } else {
        console.error("Error Login: ", error);
        message.error("You are not authorized");
      }
    } finally {
      setIsLoading(false);
    }
  };

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleForgetPasswordClick() {
    onForgetPasswordClick(false);
  }

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          padding: "30px",
          borderRadius: "2.5rem",
          height: "100vh",
          maxWidth: "100%",
        }}
      >
        <Grid
          item
          md={7}
          lg={6}
          sx={{
            height: "100%",
            display: { xs: "none", md: "flex" },
            width: { md: "58.33%", lg: "50%" },
          }}
        >
          <img
            src={loginPic}
            alt="truck on road"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: "30px",
            }}
            loading="lazy"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "30px",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          {/* logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "280px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          >
            <img src={logo} alt="logo" width="100%" />
          </Box>
          <Box
            style={{
              overflowY: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::WebkitScrollbar": {
                display: "none",
              },
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: theme.palette.text.authFont,
                textAlign: "left",
                fontSize: "1.875rem",
                fontWeight: "600",
                lineHeight: "2.5rem",
                marginBottom: "5px",
              }}
            >
              Welcome Back!
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: theme.palette.text.authFont,
                textAlign: "left",
                fontWeight: "300",
                color: theme.palette.primary.grey,
                marginBottom: "30px",
              }}
            >
              Please login here
            </Typography>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleBlur, handleChange, values, errors, touched }) => (
                <Form>
                  <Box style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email Address"
                      value={values.email}
                      onBlur={handleBlur("email")}
                      onChange={handleChange("email")}
                      placeholder="Enter Your Email"
                      required
                      error={touched.email && Boolean(errors.email)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                    {touched.email && errors?.email && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors?.email}
                      </Typography>
                    )}
                  </Box>
                  <Box style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onBlur={handleBlur("password")}
                      onChange={handleChange("password")}
                      placeholder="Enter Your Password"
                      required
                      error={touched.password && Boolean(errors.password)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "10px",
                        },
                      }}
                      InputProps={{
                        endAdornment: showPassword ? (
                          <BsEyeSlash
                            onClick={handleShowPassword}
                            style={{
                              cursor: "pointer",
                              color: theme.palette.dark.dustyBlue,
                            }}
                          />
                        ) : (
                          <BsEye
                            onClick={handleShowPassword}
                            style={{
                              cursor: "pointer",
                              color: theme.palette.dark.dustyBlue,
                            }}
                          />
                        ),
                      }}
                    />
                    {touched.password && errors?.password && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors?.password}
                      </Typography>
                    )}
                  </Box>
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={{
                      marginBottom: "30px",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "9px",
                      }}
                    >
                      <Checkbox
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        sx={{
                          padding: "0",
                        }}
                        icon={
                          <FaRegSquare
                            style={{
                              color: theme.palette.dark.dustyBlue,
                              fontSize: "24px",
                            }}
                          />
                        }
                        checkedIcon={
                          <FaCheckSquare
                            style={{
                              color: theme.palette.primary.main,
                              fontSize: "24px",
                            }}
                          />
                        }
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: theme.palette.text.authFont,
                          color: theme.palette.primary.black,
                        }}
                      >
                        Remember Me
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="body2"
                        onClick={handleForgetPasswordClick}
                        sx={{
                          fontFamily: theme.palette.text.authFont,
                          textTransform: "capitalize",
                          cursor: "pointer",
                          color: theme.palette.primary.main,
                        }}
                      >
                        Forget Password?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      fontFamily: theme.palette.text.authFont,
                      textTransform: "capitalize",
                      marginBottom: "30px",
                      borderRadius: "10px",
                      padding: "10px 16px",
                      fontSize: "1rem",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    }}
                  >
                    {isLoading ? (
                      <Loader color={theme.palette.primary.main} />
                    ) : (
                      "login"
                    )}
                  </Button>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        color: theme.palette.text.secondary,
                        "& > :not(style) ~ :not(style)": {
                          marginTop: theme.spacing(2),
                        },
                      }}
                    >
                      <Divider>or</Divider>
                    </Box>

                    <Grid
                      container
                      sx={{ gap: "40px" }}
                      justifyContent="center"
                    >
                      <SocialAuthButton
                        provider="google"
                        action="log in with"
                      />
                      {/* Conditionally render the Apple button only on Apple devices */}
                      {isAppleDevice && (
                        <SocialAuthButton
                          provider="apple"
                          action="log in with"
                        />
                      )}
                    </Grid>
                  </Box>
                  <Grid>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: theme.palette.text.authFont,
                        color: theme.palette.dark.dustyBlue,
                        lineHeight: "1.188rem",
                        textAlign: "center",
                        marginTop: "30px",
                      }}
                    >
                      Don't have an account?{" "}
                      <Link
                        to="/signup"
                        style={{
                          color: theme.palette.primary.main,
                          textDecoration: "none",
                        }}
                      >
                        Sign up
                      </Link>
                    </Typography>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default LoginForm;
