import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import rectangle from "../../../../assets/Images/background_header_Pages.png";
import requestHeader from "../../../../assets/Images/request.png";
import { useSelector } from "react-redux";
import { CiSquarePlus } from "react-icons/ci";

const HeaderContent = ({ styles }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userData = useSelector((state) => state.appState.userData);
  const [displayText, setDisplayText] = useState("");
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isRequestsPath = location.pathname === "/requests";

  const backgroundImage = isRequestsPath ? requestHeader : rectangle;

  const showSectionPaths = [
    "/services",
    "/categories",
    "/sellers",
    "/requests",
    "/contact-us",
    "/following",
    "/blogs",
    "/privacy-policy",
    "/terms-conditions",
  ];

  const shouldShowSection =
    showSectionPaths.some((path) => location.pathname.startsWith(path)) &&
    !location.pathname.startsWith("/sellers/seller-details/") &&
    location.pathname !== "/requests/add";

  useEffect(() => {
    if (location.pathname === "/services") {
      setDisplayText("Services");
    } else if (location.pathname.startsWith("/services/")) {
      setDisplayText("Services");
    } else if (location.pathname === "/categories") {
      setDisplayText("Category");
    } else if (location.pathname === "/filtered-categories") {
      setDisplayText("Products");
    } else if (location.pathname === "/filtered-services") {
      setDisplayText("Services");
    } else if (location.pathname.startsWith("/categories/")) {
      setDisplayText("Products");
    } else if (location.pathname === "/sellers") {
      setDisplayText("Sellers");
    } else if (location.pathname === "/requests") {
      setDisplayText("Requests");
    } else if (location.pathname === "/contact-us") {
      setDisplayText("Help center");
    } else if (location.pathname === "/following") {
      setDisplayText("Followings");
    } else if (location.pathname === "/blogs") {
      setDisplayText("Blogs");
    } else if (location.pathname === "/privacy-policy") {
      setDisplayText("Privacy Policy");
    } else if (location.pathname === "/terms-conditions") {
      setDisplayText("Terms and Conditions");
    } else {
      setDisplayText("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.setItem("redirectAfterLogin", window.location.pathname);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      // Store the redirect path only if it hasn't been saved yet
      if (!sessionStorage.getItem("redirectAfterLogin")) {
        sessionStorage.setItem("redirectAfterLogin", window.location.pathname);
      }
    }
  }, [userData]);

  return (
    <>
      {location?.pathname === "/" || location?.pathname === "/home" ? (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            top: 0,
            left: { xs: "20px", sm: "50px" },
            height: "100%",
            width: mdDown ? "80%" : "50%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 1,
              marginTop: { xs: "8rem", md: "10rem" },
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "14px", mb: 1 }}>
              The new way to buy and sell truck parts!
            </Typography>

            <Typography
              sx={{
                ...styles.headerTitle,
                lineHeight: { xs: "3rem", md: "61.6px" },
                fontSize: { xs: "2rem", md: "3.5rem" },
              }}
            >
              Welcome to TRUCKPARTSMART!
            </Typography>
            <Typography sx={styles.descriptionText}>
              TRUCKPARTSMART is an app connecting buyers and sellers of truck
              parts while also helping users find essential trucking services.
              With features like the HOTLINE REQUEST, real-time chat, and
              location-based filters, it streamlines the process, saving time
              and money.
            </Typography>

            <Box>
              <Button
                onClick={() => {
                  navigate(isLoggedIn ? "/selling" : "/login");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                sx={styles.startSelling}
              >
                Start Selling
              </Button>

              <Button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/categories");
                }}
                sx={styles.orderNow}
              >
                Shop now
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
             
              backgroundColor: theme.palette.primary.light,
              borderTop: `4px solid ${theme.palette.primary.background}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb />
          </Box>
          <Box
            sx={{
              background: theme.palette.primary.background,
              marginBottom: "0px",
              paddingTop: "20px",
            }}
          >
            {shouldShowSection && !isRequestsPath && (
              <Box
                sx={{
                  position: "relative",
                  background: `url(${backgroundImage}) center center / cover no-repeat`,
                  height: "240px",
                  borderRadius: isSmallScreen ? "0px" : "25px",
                  margin: { xs: "0", sm: "0px", md: "25px", lg: "25px" },
                  color: theme.palette.primary.light,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: theme.typography.headerFont,
                      fontSize: "56px",
                      fontWeight: 400,
                      lineHeight: "61.6px",
                      textAlign: "center",
                      color: theme.palette.primary.light,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {displayText}
                  </Typography>
                </Box>
              </Box>
            )}
          
          </Box>
        </>
      )}
    </>
  );
};

export default HeaderContent;
