import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import { message } from "antd";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Timestamp } from "firebase/firestore";
import { auth, db } from "../../configs/firebase";
import { setUserData } from "../../redux/slices/appStateSlice";
import SocialAuthButton from "../../components/common/SocialAuthButton";

// images
import sigupPic from "../../assets/Images/signup_side_img_truck_on_road.png";
import logo from "../../assets/Logo/logoBlack.png";
import Loader from "../../components/common/Loader";

const SignUP = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();

  // Detect if the user is on an Apple device
  const isAppleDevice = /iPhone|iPad|Macintosh/i.test(navigator.userAgent);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required")
      .min(3, "First name must be at least 3 characters long")
      .max(10, "First name must be at most 10 characters long"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must be at least 3 characters long")
      .max(10, "Last name must be at most 10 characters long"),
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      ),

    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const emailExist = async (email) => {
    const userSnapshot = await db
      .collection("users")
      .where("email", "==", email)
      .where("isDeleted", "==", false)
      .get();
    return !userSnapshot.empty;
  };

  const emailExistDeleted = async (email) => {
    const userSnapshot = await db
      .collection("users")
      .where("email", "==", email)
      .where("isDeleted", "==", true)
      .get();
    return !userSnapshot.empty;
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setEmailError("");
    const { firstName, lastName, email, password } = values;
    try {
      const emailAlreadyExists = await emailExist(email);
      if (emailAlreadyExists) {
        setEmailError("Email already exists");
        setIsLoading(false);
        return;
      }

      const emailAlreadyExistsDeleted = await emailExistDeleted(email);
      if (emailAlreadyExistsDeleted) {
        setEmailError("This email is deleted. Please contact support.");
        setIsLoading(false);
        return;
      }
      // Create user with email and password
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = userCredential.user;
      const currentDate = new Date();

      // Convert the Date object to a Firestore Timestamp for storage
      const firestoreTimestamp = Timestamp.fromDate(currentDate);

      // Check if the user has a default address
      const defaultAddressSnapshot = await db
        .collection("users")
        .doc(user.uid)
        .collection("addresses")
        .where("isDefault", "==", true)
        .limit(1)
        .get();

      let defaultAddress = "";
      if (!defaultAddressSnapshot.empty) {
        // Assuming each document represents an address
        defaultAddress = defaultAddressSnapshot.docs[0].data(); // Retrieve the address object
      }

      const userData = {
        uid: user.uid,
        email: user.email,
        firstName: firstName,
        lastName: lastName,
        middleName: "",
        profilePicture: null,
        phoneNumber: "",
        websiteLink: "",
        registeredSince: firestoreTimestamp,
        followersCount: 0,
        address: null,
        completionRate: 0.0,
        hasLinkedAccount: false,
        activeProducts: 0,
        lastLoginInfo: {
          buildNumber: 0,
          lastLogin: firestoreTimestamp,
          osType: "web",
          version: "",
        },
        isDisabled: false,
        isDeleted: false,
        userType: "buyer",
        taxRate: 0,
        createdAt: firestoreTimestamp,
        updatedAt: firestoreTimestamp,
        cart: null,
        paymentRestricted: true,
        // cart: {
        //   // items:[],
        //   // location:cartLocation
        // },
      };
      await db.collection("users").doc(user.uid).set(userData, { merge: true });
      dispatch(setUserData(userData));
      message.success("We’re Just Getting Started!");
      setIsLoading(false);

      // Check if user is logged in
      const redirectPath = sessionStorage.getItem("redirectAfterLogin");
      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate("/home");
      }
    } catch (error) {
      message.error(`Sign up failed. Please try again`);
      console.error("Error signing up", error);
    } finally {
      setIsLoading(false);
    }
  };

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }
  function handleShowPasswordConfirm() {
    setShowPasswordConfirm(!showPasswordConfirm);
  }

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          padding: "30px",
          borderRadius: "2.5rem",
          height: "100vh",
          maxWidth: "100%",
        }}
      >
        <Grid
          item
          md={7}
          lg={6}
          sx={{
            height: "100%",
            display: { xs: "none", md: "flex" },
            width: { md: "58.33%", lg: "50%" },
          }}
        >
          <img
            src={sigupPic}
            alt="truck on road"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: "30px",
            }}
            loading="lazy"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "30px",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          {/* logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "280px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          >
            <img src={logo} alt="logo" width="100%" />
          </Box>
          <Box
            style={{
              overflowY: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::WebkitScrollbar": {
                display: "none",
              },
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: theme.palette.text.authFont,
                textAlign: "left",
                fontSize: "1.875rem",
                lineHeight: "2.5rem",
                fontWeight: "600",
                marginBottom: "5px",
              }}
            >
              Sign Up!
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: theme.palette.text.authFont,
                textAlign: "left",
                color: theme.palette.primary.grey,
                fontWeight: "300",
                marginBottom: "30px",
              }}
            >
              Please register here{" "}
            </Typography>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleBlur, handleChange, values, errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Box>
                        <TextField
                          fullWidth
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          value={values?.firstName}
                          onBlur={handleBlur("firstName")}
                          onChange={handleChange("firstName")}
                          placeholder="Enter Your First Name"
                          required
                          error={touched.firstName && Boolean(errors.firstName)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiInputBase-input": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                        {touched.firstName && errors?.firstName && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors?.firstName}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          value={values?.lastName}
                          onBlur={handleBlur("lastName")}
                          onChange={handleChange("lastName")}
                          placeholder="Enter Your Last Name"
                          required
                          error={touched.lastName && Boolean(errors.lastName)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiInputBase-input": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                        {touched.lastName && errors?.lastName && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors?.lastName}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email Address"
                          autocomplete="false"
                          value={values?.email}
                          onBlur={handleBlur("email")}
                          onChange={(e) => {
                            handleChange(e);
                            setEmailError("");
                          }}
                          placeholder="Enter Your Email"
                          required
                          error={touched.email && Boolean(errors.email)}
                          autoComplete="off"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiInputBase-input": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                        {touched.email && errors?.email && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors?.email}
                          </Typography>
                        )}
                        {emailError && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {emailError}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          id="password"
                          name="password"
                          label="Password"
                          // autoComplete="false"
                          type={showPassword ? "text" : "password"}
                          value={values?.password}
                          onBlur={handleBlur("password")}
                          onChange={handleChange("password")}
                          placeholder="Enter Your Password"
                          required
                          autoComplete="new-password"
                          error={touched.password && Boolean(errors.password)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiInputBase-input": {
                              borderRadius: "10px",
                            },
                          }}
                          InputProps={{
                            endAdornment: showPassword ? (
                              <BsEyeSlash
                                onClick={handleShowPassword}
                                style={{
                                  cursor: "pointer",
                                  color: theme.palette.dark.dustyBlue,
                                }}
                              />
                            ) : (
                              <BsEye
                                onClick={handleShowPassword}
                                style={{
                                  cursor: "pointer",
                                  color: theme.palette.dark.dustyBlue,
                                }}
                              />
                            ),
                          }}
                        />
                        {touched.password && errors?.password && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors?.password}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          id="confirmPassword"
                          name="confirmPassword"
                          label="Confirm Password"
                          type={showPasswordConfirm ? "text" : "password"}
                          value={values.confirmPassword}
                          onBlur={handleBlur("confirmPassword")}
                          onChange={handleChange("confirmPassword")}
                          autoComplete="off"
                          required
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiInputBase-input": {
                              borderRadius: "10px",
                            },
                          }}
                          InputProps={{
                            endAdornment: showPasswordConfirm ? (
                              <BsEyeSlash
                                onClick={handleShowPasswordConfirm}
                                style={{
                                  cursor: "pointer",
                                  color: theme.palette.dark.dustyBlue,
                                }}
                              />
                            ) : (
                              <BsEye
                                onClick={handleShowPasswordConfirm}
                                style={{
                                  cursor: "pointer",
                                  color: theme.palette.dark.dustyBlue,
                                }}
                              />
                            ),
                          }}
                        />
                        {touched.confirmPassword && errors.confirmPassword && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {errors?.confirmPassword}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={isLoading}
                      sx={{
                        fontFamily: theme.palette.text.authFont,
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontSize: "1rem",
                        padding: "10px 16px",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                      }}
                    >
                      {isLoading ? (
                        <Loader color={theme.palette.primary.main} />
                      ) : (
                        "Sign up"
                      )}
                    </Button>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          color: theme.palette.text.secondary,
                          "& > :not(style) ~ :not(style)": {
                            marginTop: theme.spacing(2),
                          },
                        }}
                      >
                        <Divider>or</Divider>
                      </Box>

                      <Grid
                        container
                        sx={{ gap: "40px" }}
                        justifyContent="center"
                      >
                        <SocialAuthButton
                          provider="google"
                          action="sign up with"
                        />

                        {/* Conditionally render the Apple button only on Apple devices */}
                        {isAppleDevice && (
                          <SocialAuthButton
                            provider="apple"
                            action="sign up with"
                          />
                        )}
                      </Grid>
                    </Box>
                    <Grid>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: theme.palette.text.authFont,
                          color: theme.palette.dark.dustyBlue,
                          lineHeight: "1.188rem",
                          textAlign: "center",
                        }}
                      >
                        Already have an account?{" "}
                        <Link
                          to="/login"
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: "none",
                            fontWeight: "600",
                          }}
                        >
                          Login
                        </Link>
                      </Typography>
                    </Grid>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default SignUP;
