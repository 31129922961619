import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Box,
} from "@mui/material";
import { FaFacebookF } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import googlePlay from "../../../assets/Images/gApp.svg";
import applePlay from "../../../assets/Images/appleApp.svg";
import logoBlack from "../../../assets/Logo/logoBlack.png";
import { useSelector } from "react-redux";
import { SOCIAL_LINKS } from "../../layout/socialLinks";

function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = useSelector((state) => state.appState.userData);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const styles = {
    footerContainer: {
      padding: theme.spacing(4, 0),
    },
    footerTitle: {
      fontWeight: 800,
      lineHeight: "1.4rem",
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
      textAlign: isSmallScreen ? "center" : "left",
    },
    footerItem: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      display: "block",
      width: isSmallScreen ? "100%" : "fit-content",
      padding: theme.spacing(0.5, 0),
      marginBottom: theme.spacing(0.2),
      textAlign: isSmallScreen ? "center" : "left",

      transition: "color 0.3s ease",
    },
    logoContainer: {
      marginBottom: theme.spacing(2),
      textAlign: isSmallScreen ? "center" : "left",
    },
    logoText: {
      fontSize: ".8rem",
      color: theme.palette.text.primary,
      opacity: 0.7,
      marginBottom: theme.spacing(2),
      textAlign: isSmallScreen ? "center" : "left",
    },
    socialIcons: {
      display: "flex",
      gap: theme.spacing(1),
      marginBottom: theme.spacing(2),
      justifyContent: isSmallScreen ? "center" : "left",
    },
    socialIcon: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.primary.light,
      borderRadius: "50%",
      fontSize: "1.4rem",
      display: "flex",
      width: "32px",
      height: "32px",
      alignItems: "center",
      justifyContent: "center",
    },
    appIcons: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    copyright: {
      marginTop: theme.spacing(4),
      textAlign: "center",
      lineHeight: "24px",
      fontSize: ".7rem",
      color: theme.palette.text.primary,
      opacity: 0.7,
    },
    divider: {
      width: "100%",
      height: "1px",
      backgroundColor: theme.palette.action.hover,
      margin: "32px 0",
    },
  };

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  return (
    <footer style={styles.footerContainer}>
      <Grid
        container
        spacing={0}
        sx={{
          padding: "0px 25px",
          alignItems: "start",
        }}
      >
        <Grid item xs={12} sm={12} md={3}>
          <Box style={styles.logoContainer}>
            <Box
              onClick={() => navigate("/")}
              sx={{
                cursor: "pointer",
                width: { xs: "100%", sm: "220px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <img src={logoBlack} alt="logo" loading="lazy" width="100%" />
            </Box>
            <Typography variant="body1" style={styles.footerItem}>
              Download the app today and be part of the new TRUCKPARTSMART
              marketplace! It's the only marketplace app strictly for truck
              parts and truck-related services.
            </Typography>
          </Box>
          <div style={styles.socialIcons}>
            <Link to={SOCIAL_LINKS.facebook} style={styles.socialIcon}>
              <FaFacebookF />
            </Link>

            <Link to={SOCIAL_LINKS.instagram} style={styles.socialIcon}>
              <TiSocialInstagram />
            </Link>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography variant="body1" style={styles.footerTitle}>
              About
            </Typography>

            <Link
              to="/about-us"
              variant="body1"
              style={styles.footerItem}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              About TRUCKPARTSMART
            </Link>

            <Link
              to="/privacy-policy"
              variant="body1"
              style={styles.footerItem}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Privacy and Policy
            </Link>

            <Link
              to="/terms-conditions"
              variant="body1"
              style={styles.footerItem}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Terms and Conditions
            </Link>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            marginBottom: { xs: "0", md: "30px" },
          }}
        >
          <div>
            <Typography variant="body1" style={styles.footerTitle}>
              Information
            </Typography>
            <Link
              to="/info-truckpartsmart"
              variant="body1"
              style={styles.footerItem}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Info TRUCKPARTSMART
            </Link>
            {isLoggedIn && (
              <Link
                to="/contact-us"
                variant="body1"
                style={styles.footerItem}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                Help Center
              </Link>
            )}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography variant="body1" style={styles.footerTitle}>
              For users
            </Typography>
            {!isLoggedIn ? (
              <>
                <Link
                  to="/logIn"
                  variant="body1"
                  style={styles.footerItem}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Login
                </Link>
                <Link
                  to="/signUp"
                  variant="body1"
                  style={styles.footerItem}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Register
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/profile?page=orders"
                  variant="body1"
                  style={styles.footerItem}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  My Orders
                </Link>
              </>
            )}
            <Link
              to="/profile?page=info&form=personal"
              variant="body1"
              style={styles.footerItem}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Settings
            </Link>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            marginBottom: { xs: "0", md: "33px" },
          }}
        >
          <Box>
            <Typography variant="body1" style={styles.footerTitle}>
              Get the app
            </Typography>
            <div style={styles.appIcons}>
              <img
                src={googlePlay}
                alt="Google Play"
                width="100px"
                style={{
                  margin: isSmallScreen ? "auto" : "0",
                  width: "143.92px",

                  cursor: "pointer",
                  display: "block",
                  zIndex: 10,
                }}
                onClick={() =>
                  window.open(
                    " https://play.google.com/store/apps/details?id=com.zetaton.truckparts",
                    "_blank"
                  )
                }
              />
              <img
                src={applePlay}
                alt="App Store"
                width="100px"
                style={{
                  margin: isSmallScreen ? "auto" : "0",
                  width: "143.92px",
                  cursor: "pointer",
                  display: "block",
                  zIndex: 10,
                }}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/truckpartsmart/id6499494008?platform=iphone",
                    "_blank"
                  )
                }
              />
            </div>
          </Box>
        </Grid>
      </Grid>
      <Divider style={styles.divider} />
      <Typography style={styles.copyright}>
        &copy; Truckpartsmart {new Date().getFullYear()}. All rights reserved.
      </Typography>
    </footer>
  );
}

export default Footer;
