import React from "react";
import { createBrowserRouter } from "react-router-dom";
import SignUP from "../pages/signUP/SignUP";
import LogIn from "../pages/logIn/LogIn";
import Home from "./../pages/home/Home";
import Categories from "./../pages/categories/Categories";
import Services from "./../pages/services/Services";
import Sellers from "./../pages/sellers/Sellers";
import Requests from "./../pages/requests/Requests";
import Blogs from "./../pages/blogs/Blogs";
import NotFound from "./../pages/notFound/NotFound";
import AddRequests from "../pages/requests/AddRequests";
import Layout from "../components/layout/Layout";
import ProductDetails from "../pages/products/ProduuctDetails.jsx";
import ProfileLayout from "../pages/profile/ProfileLayout.jsx";
import Cart from "../pages/cart/Cart.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import AirSystem from "../pages/airSystem/AirSystem";
import CheckOut from "../pages/checkOut/CheckOut.jsx";
import ListItems from "../pages/userItemPage/SellingPage/ListItems.jsx";
import AddItem from "../pages/userItemPage/addItems/AddItem.jsx";
import AddService from "../pages/userItemPage/AddService/AddService.jsx";
import ServiceBillingDetails from "../pages/userItemPage/AddService/ServiceBillingDetails.jsx";
import EditService from "../pages/userItemPage/EditService/EditService.jsx";
import EditServiceBillingDetails from "../pages/userItemPage/EditService/EditServiceBillingDetails.jsx";
import ServiceBusinessInfo from "../pages/userItemPage/AddService/ServiceBusinessInfo.jsx";
import ItemsBusinessInfo from "../pages/userItemPage/addItems/ItemsBusinessInfo.jsx";
import OrderDetails from "../pages/orderDetails/OrderDetails.jsx";
import TrackOrder from "../pages/trackOrder/TrackOrder.jsx";
import SellerDetails from "../pages/sellers/SellerDetails.jsx";
import Chat from "../pages/chat/Chat.jsx";
import TermsConditions from "../pages/UserGuidelines/TermsConditions.jsx";
import PrivacyPolicy from "../pages/UserGuidelines/PrivacyPolicy.jsx";
import Following from "../pages/following/Following.jsx";
import ContactUS from "./../pages/contactUs/ContactUS";
import DynamicPage from "../pages/userItemPage/addItems/dynamicPage/DynamicPage.jsx";
import Reviews from "../pages/reviews/Reviews.jsx";
import ListMap from "./../pages/ListMap/ListMap";
import SuccessOrder from "../components/common/SuccessOrder.jsx";
import AboutUs from "../pages/home/AboutUs.jsx";
import InfoTRUCKPARTSMART from "../pages/home/components/InfoTRUCKPARTSMART.jsx";

const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/categories",
        element: <Categories />,
      },

      {
        path: "/about-us",
        element: <AboutUs />,
      },
      
      {
        path: "/info-truckpartsmart",
        element: <InfoTRUCKPARTSMART />,
      },

      {
        path: "/services",
        element: <Services />,
      },

      {
        path: "/filtered-categories",
        element: <AirSystem />,
      },
      {
        path: "/filtered-services",
        element: <AirSystem />,
      },
      {
        path: "/product/:id?",
        element: <ProductDetails />,
      },
      {
        path: "/service-details/:id?",
        element: <ProductDetails />,
      },
      {
        path: "/sellers",
        element: <Sellers />,
      },
      {
        path: "/sellers/seller-details/:id?",
        element: <SellerDetails />,
      },
      {
        path: "/selling",
        element: <ProtectedRoute element={<ListItems />} />,
      },
      {
        path: "/selling/add-business-info",
        element: <ProtectedRoute element={<ItemsBusinessInfo />} />,
      },
      {
        path: "/selling/edit-item/:productId?",
        element: <ProtectedRoute element={<AddItem />} />,
      },

      {
        path: "/selling/:pageName/:productId?",
        element: <ProtectedRoute element={<DynamicPage />} />,
      },

      {
        path: "/selling/add-service",
        element: <ProtectedRoute element={<AddService />} />,
      },

      {
        path: "/selling/service-business-form",
        element: <ProtectedRoute element={<ServiceBusinessInfo />} />,
      },
      {
        path: "/selling/add-service-billing&ShippingDetails",
        element: <ProtectedRoute element={<ServiceBillingDetails />} />,
      },
      {
        path: "/selling/edit-service/:id",
        element: <ProtectedRoute element={<EditService />} />,
      },

      {
        path: "/selling/edit-service-billing&ShippingDetails/:id",
        element: <ProtectedRoute element={<EditServiceBillingDetails />} />,
      },
      // {
      //   path: "/selling/upload-csv",
      //   element: <ProtectedRoute element={<UploadCSVPage />} />,
      // },
      // {
      //   path: "/selling/upload-csv/view-products",
      //   element: <ProtectedRoute element={<ViewProducts />} />,
      // },
      // {
      //   path: "/selling/upload-csv/confirmation",
      //   element: <ProtectedRoute element={<ConfirmCSV />} />,
      // },
      {
        path: "/requests",
        element: <Requests />,
      },
      {
        path: "/following",
        element: <Following />,
      },
      {
        path: "/requests/add",
        element: <ProtectedRoute element={<AddRequests />} />,
      },

      {
        path: "/checkout",
        element: <ProtectedRoute element={<CheckOut />} />,
      },
      {
        path: "/contact-us",
        element: <ProtectedRoute element={<ContactUS />} />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/explore",
        element: <ListMap />,
      },
      {
        path: "/cart",
        element: <ProtectedRoute element={<Cart />} />,
      },
      {
        path: "/profile",
        element: <ProtectedRoute element={<ProfileLayout />} />,
      },
      {
        path: "/orderDetails/:id",
        element: <ProtectedRoute element={<OrderDetails />} />,
      },
      {
        path: "/trackOrder/:id",
        element: <ProtectedRoute element={<TrackOrder />} />,
      },

      { path: "/chat/:type?", element: <ProtectedRoute element={<Chat />} /> },
      {
        path: "/chat/:type?/:chatId/message/:lastMessageId",
        element: <ProtectedRoute element={<Chat />} />,
      },
      {
        path: "/chats",
        element: <ProtectedRoute element={<Chat />} />,
      },
      {
        path: "/reviews",
        element: <ProtectedRoute element={<Reviews />} />,
      },
      {
        path: "/terms-conditions",
        element: <TermsConditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/success-order",
        element: <ProtectedRoute element={<SuccessOrder />} />,
      },
    ],
  },
  {
    path: "/signUp",
    element: <SignUP />,
  },
  {
    path: "/logIn",
    element: <LogIn />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default appRoutes;
