import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { IoIosArrowForward } from "react-icons/io";
import StoreButtons from "../StoreButton";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const theme = useTheme();

  // Function to format value (separate words, lowercase second word, remove hyphens)
  const formatValue = (value) => {
    const formatted = value.replace(/-/g, " ");

    return formatted
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\s([A-Z])/, (match) => match.toLowerCase());
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row" },
        width: { xl: "95%", md: "92%", sm: "85%", xs: "100%" },
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          margin: "20px 0",
          padding: { xs: "0px 10px", sm: "0px 30px" },
          textAlign: "left",
          width: "100%",
        }}
        separator={
          <IoIosArrowForward style={{ color: theme.palette.primary.arrow }} />
        }
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: theme.palette.dark.dustyBlue,
          }}
        >
          Home
        </Link>
        {pathnames.map((value, index) => {
          let last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isAllLettersOrHyphen =
            /^[a-zA-Z-]+$/.test(value) && value?.length < 20;
          let displayValue;
          // Logic to display 'Profile' for 'orderDetails' and link to /profile
          if (value === "orderDetails" || value === "trackOrder") {
            displayValue = "Profile";

            return (
              <Link
                to="/profile?page=orders"
                key={to}
                style={{
                  textDecoration: "none",
                  color: last ? theme.palette.text.primary : "inherit",
                  fontWeight: last ? 500 : 400,
                }}
              >
                {displayValue}
              </Link>
            );
          }

          if (to === "/requests/add") {
            const requestId = location.state?.requestId;
            displayValue = requestId ? "Edit Request" : "Add Request";
          } else if (value.includes("edit-service-billing&ShippingDetails")) {
            displayValue = "Update Service";
          } else if (value.includes("add-service-billing&ShippingDetails")) {
            displayValue = "Add Service";
          } else if (to === "/explore") {
            displayValue = "Map";
          } else if (to === "/cart") {
            displayValue = "checkout";
          } else if (to === "/transactions-history") {
            displayValue = "Transaction history";
          } else if (to === "/product") {
            displayValue = "Product Details";
            last = true;
          } else if (index > 0 && pathnames[index - 1] === "categories") {
            displayValue = "Products";
          } else if (index > 0 && pathnames[index - 1] === "services") {
            displayValue = "Services";
          } else if (last && !isAllLettersOrHyphen) {
            return null;
          } else {
            displayValue = formatValue(
              value.charAt(0).toUpperCase() + value.slice(1)
            );
          }

          return last ? (
            <Typography
              style={{ fontWeight: 500, color: theme?.palette.text.primary }}
              key={to}
            >
              {displayValue}
            </Typography>
          ) : (
            <Link
              to={to}
              key={to}
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: 500,
              }}
            >
              {displayValue}
            </Link>
          );
        })}
      </Breadcrumbs>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: "row",
          alignItems: "center",
        
        }}
      >
        <StoreButtons />
      </Box>
    </Box>
  );
};

export default Breadcrumb;
