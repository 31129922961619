import React from "react";
import { Typography, useTheme, Box } from "@mui/material";
import about from "../../../assets/Images/about.png";
import WhyTruck from "./WhyTruck";

// Reusable Text Section Component
const TextSection = ({ children, color, mb = 2, fontSize = "14px" }) => (
  <Typography
    variant="body1"
    sx={{
      fontSize: fontSize,
      lineHeight: "30px",
      color: color || "text.primary", // Default color if not passed
      mb: mb,
    }}
  >
    {children}
  </Typography>
);

const InfoTRUCKPARTSMART = () => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ margin: { xs: 0, sm: "0 30px" } }}>
        <Box
          sx={{
            position: "relative",
            backgroundImage: `url(${about})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "300px",
            color: theme.palette.primary.light,
            display: "flex",
            alignItems: "center",
            p: 3,
            borderRadius: { xs: "0", sm: "25px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: theme.typography.headerFont,
                fontSize: { xs: "1.5rem", sm: "2rem" },
                fontWeight: 400,
                lineHeight: "61.6px",
                color: theme.palette.primary.light,
              }}
            >
              Info TRUCKPARTSMART
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
                mb: 2,
                color: theme.palette.primary.light,
              }}
            >
              Welcome to TRUCKPARTSMART!
            </Typography>

            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
                width: { xs: "100%", sm: "100%", md: "80%" },
                lineHeight: "30px",
                paddingRight: { xs: "40px", sm: 0, md: 0 },
                color: theme.palette.primary.descHome,
              }}
            >
              We created this new app to help everyone in any sector of the
              trucking industry to assist in buying and/or selling truck parts.
              It also helps in locating services that are regularly needed by
              anyone operating businesses that rely on keeping their equipment
              on the road and out of the shop for as long as possible.
            </Typography>
          </Box>
        </Box>
      </Box>

      <section
        style={{
          background: theme.palette.primary.light,
          padding: "30px 20px 0 20px",
        }}
      >
        <TextSection color={theme.palette.primary.darkBlue}>
          One feature that we are especially excited to offer is the
          TRUCKPARTSMART HOTLINE. This feature enables users to post their
          needs—whether for parts or services—on the HOTLINE REQUESTS page.
          Once a user posts their request, it will immediately show up on a
          scroll of requests that suppliers, service providers, and other users
          will be able to see in real-time.
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          Just imagine that you will no longer have to spend hours on the phone
          trying to track down the right part at the right price from a
          convenient location. Instead, by using the TRUCKPARTSMART app, and
          taking advantage of our HOTLINE REQUEST feature, you will have
          suppliers calling you! This will put you in the driver’s seat when
          negotiating the best price, the best parts, and the best terms.
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          No longer will you experience the frustration of searching all over
          the country for the needed part and having to wait weeks for delivery;
          and then finding out the supplier sent you the wrong or defective
          part. And how many times after overpaying for a part from some distant
          supplier, have you later been at a neighbor’s or a friend’s facility
          to find what you needed was sitting just blocks away for a fraction of
          the price? Never again!
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          By using the TRUCKPARTSMART app, this shouldn’t happen to you anymore.
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          The creators of the TRUCKPARTSMART app have been involved in the truck
          parts/truck service business for over 30 years. The app was designed
          to help users overcome as many of the challenges they face searching
          for parts and services, while at the same time, it enables its users
          to have an outlet for the valuable parts that they have in their
          inventories.
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          Best of all, this new TRUCKPARTSMART resource is all available at your
          fingertips. The TRUCKPARTSMART app is available at the App Store and
          the Google Play Store. The TRUCKPARTSMART app also has a companion
          website found at TRUCKPARTSMART.com.
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          The TRUCKPARTSMART app has chat features that allow for direct and
          instant communication between the user that posted the request and
          anyone that is able to fulfill their needs. It gives users the ability
          to filter their requests to a certain geographical area. It will also
          show where suppliers are located by showing a pin on the map view
          feature of where the supplier is in relation to your own location.
        </TextSection>

        <TextSection color={theme.palette.primary.darkBlue}>
          Before long, the collective of TRUCKPARTSMART users will have the
          largest inventory of truck parts available anywhere. Not even the
          largest parts yards will be able to compete with the selection
          available on the TRUCKPARTSMART app. Get the TRUCKPARTSMART app today,
          and become part of this new marketplace!
        </TextSection>
      </section>

      <Box>
        <WhyTruck background={"about"} />
      </Box>
    </>
  );
};

export default InfoTRUCKPARTSMART;
