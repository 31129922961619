
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Typography, List, ListItem, useTheme } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AccountInfo from "./components/accountInfo/AccountInfo";
import SavedAddresses from "./components/savedAddress/SavedAddresses";
import MyOrders from "./components/myOrders/MyOrders";
import SavedCards from "./components/SavedCards";
import Favorites from "./components/Favorites";
import { useDispatch, useSelector } from "react-redux";
import { resetFormTouched } from "../../redux/slices/appStateSlice";
import { Modal } from "antd";
import TransactionsHistory from "./components/transactionsHistory/TransactionsHistory";

function ProfileLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation(); 
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "info"; // Default to 'info'
  const validPages = ["info", "addresses", "orders", "transactions-history", "favorites"];
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  const [localIsFormTouched, setLocalIsFormTouched] = useState(isFormTouched);
  const dispatch = useDispatch();
  const confirmedLeave = useRef(false); // Use useRef to persist the value

  // Set default page to 'info' if none is specified
  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: "info" });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setLocalIsFormTouched(isFormTouched);
  }, [isFormTouched]);

  // If 'page' is invalid, redirect to default (personal) form
  useEffect(() => {
    if (!validPages.includes(page)) {
      navigate("/profile?page=info&form=personal", { replace: true });
    }
  }, [page]);

  useEffect(() => {
    setLocalIsFormTouched(false);
  }, []);

  const showConfirmModal = useCallback((onOk, onCancel) => {
    Modal.confirm({
      title: "Unsaved Changes",
      content: "You have unsaved changes. Are you sure you want to leave this page?",
      okText: "Yes, leave page",
      cancelText: "Stay on page",
      onOk,
      onCancel,
      centered: true,
    });
  }, []);

  useEffect(() => {
    confirmedLeave.current = false;
  }, [localIsFormTouched]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (localIsFormTouched && !confirmedLeave.current) {
        event.preventDefault();
        showConfirmModal(
          () => {
            confirmedLeave.current = true;
            dispatch(resetFormTouched());
            navigate(-1);
          },
          () => {
            window.history.pushState(null, "", location.pathname + location.search);
          }
        );
      }
    };
    window.history.pushState(null, "", location.pathname + location.search);
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [localIsFormTouched]);

  useEffect(() => {
    const handleOnBeforeUnload = (event) => {
      if (localIsFormTouched && !confirmedLeave.current) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    const handleOnUnload = () => {
      if (localIsFormTouched && !confirmedLeave.current) {
        dispatch(resetFormTouched());
      }
    };

    window.addEventListener("beforeunload", handleOnBeforeUnload, {
      capture: true,
    });
    window.addEventListener("unload", handleOnUnload, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload, {
        capture: true,
      });
      window.removeEventListener("unload", handleOnUnload, { capture: true });
    };
  }, [localIsFormTouched]);

  const handleNavigation = (path) => {
    if (localIsFormTouched) {
      showConfirmModal(
        () => navigateToPage(path),
        () => {}
      );
    } else {
      navigateToPage(path);
    }
  };

  const navigateToPage = (path) => {
    navigate(`/profile?page=${path}`);
    dispatch(resetFormTouched());
  };

  const menuItems = [
    { label: "account info", path: "info" },
    { label: "saved addresses", path: "addresses" },
    { label: "my orders", path: "orders" },
    { label: "transaction history", path: "transactions-history" },
    { label: "favorites", path: "favorites" },
  ];

  const renderComponent = () => {
    switch (page) {
      case "info":
        return <AccountInfo />;
      case "addresses":
        return <SavedAddresses />;
      case "orders":
        return <MyOrders />;
      case "transactions-history":
        return <TransactionsHistory />;
      case "favorites":
        return <Favorites />;
      default:
        return <AccountInfo />;
    }
  };


  const isActive = (path) => {
    return location.search.includes(`page=${path}`);
  };

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.primary.background,
        minHeight: "100vh",
        paddingX: {
          xs: "0px",
          md: '50px',
          xl: "100px",
        },
        paddingY: "50px",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 3fr" },
          gap: "20px",
        }}
      >
        <Box
          sx={{
            height: "23.625rem",
            backgroundColor: theme.palette.primary.light,
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.neutral.lightPaleGray,
              paddingX: "24px",
              paddingY: "10px",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "700",
                color: theme.palette.primary.lightDoveGrey,
                textTransform: "capitalize",
              }}
            >
              my account
            </Typography>
          </Box>
          <List
            sx={{
              height: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingX: "20px",
            }}
          >
            {menuItems?.map((item) => (
              <ListItem
                key={item?.path}
                onClick={() => handleNavigation(item?.path)}
                sx={{
                  borderRadius: isActive(item?.path) ? `4px` : "inherit",
                  backgroundColor: isActive(item?.path)
                    ? `${theme.palette.primary.main}`
                    : "inherit",
                  color: isActive(item.path)
                    ? `${theme.palette.primary.light}`
                    : `${theme.palette.primary.lightDoveGrey}`,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Typography
                  sx={{ fontWeight: isActive(item?.path) ? "500" : "400" }}
                  variant="body1"
                >
                  {item.label}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
       
        <Box
          sx={{
            backgroundColor:page === "transactions-history" ? "transparent" : theme.palette.primary.light,
            borderRadius: "16px",
            overflow: "hidden",
            padding: page === "orders" ? "" : "24px",
            boxShadow:page === "transactions-history" ? "" : `0px 0px 76px 0px ${theme.palette.customShadows.darkLight}`,
          }}
        >
          {renderComponent()}
        </Box>
      </Box>
    </Box>
  );
}

export default ProfileLayout;

